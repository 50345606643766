<template>
    <div class="answer-check">
        <button type="button" class="btn-block-option close-modal" @click="$modal.hide('AnswerCheckModal')"> ×</button>
        <h3 class="block-title h3">Проверка</h3>
        <div class="answer-check__block">
            <span class="answer-check__title">Вопрос</span>
            <span class="answer-check__text">{{questionData.question}}</span>
        </div>
        <div class="answer-check__block">
            <span class="answer-check__title">Ответ</span>
            <span class="answer-check__text">{{questionData.answered}}</span>
        </div>
        <div class="answer-check__block answer-check__block--correct">
            <span class="answer-check__title">Правильный ответ</span>
            <span class="answer-check__text">{{questionData.answer}}</span>
        </div>
        <div class="answer-check__form mt-20">
            <div class="form-radio">
                <label class="css-control">
                    <input
                        type="radio"
                        id="id-right"
                        :value="true"
                        v-model="is_correct"
                        name="is_active"
                        class="css-control-input"
                    >
                    <span class="css-control-indicator"></span> Правильно
                </label>
            </div>
            <div class="form-radio ml-50">
                <label class="css-control">
                    <input
                        type="radio"
                        id="id-not-right"
                        v-model="is_correct"
                        name="is_active"
                        class="css-control-input"
                    >
                    <span class="css-control-indicator"></span> Неправильно
                </label>
            </div>
        </div>
        <div class="form-group row justify-content-start mt-20 mb-0">
            <div class="col-auto">
                <button
                    @click="onSubmit"
                    type="submit"
                    class="btn btn-rounded btn-primary-dark min-width-125"
                    :disabled="false"
                >
                    Сохранить
                </button>
            </div>
            <div class="col-auto">
                <button
                    type="button"
                    class="btn btn-rounded btn-noborder btn-outline-secondary"
                    @click="$modal.hide('AnswerCheckModal')"
                >
                    Отмена
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: "AnswerCheckModal",
        props: ['answer'],
        data() {
            return {
                questionData: {},
                is_correct: true
            }
        },
        methods: {
            async onSubmit() {
                try {
                    let data = {
                        'is_correct': this.is_correct
                    };
                    let request = session.post(`/api/v1/testing-answer-wait/${this.answer.id}/${this.answer.question_id}/save/`, data);
                    let response = await request;
                    this.$swal({
                        title: 'Отлично!',
                        text: 'Вопрос обработан',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1400,
                    });
                    this.$modal.hide('AnswerCheckModal', {item: this.answer})
                } catch (err) {
                    console.error(err);
                }
            },
            async loadQuestion() {
                const request = await session.get(`/api/v1/testing-answer-wait/${this.answer.id}/${this.answer.question_id}/`);
                this.questionData = request.data;
            }
        },
        async created() {
            await this.loadQuestion()
        }
    }
</script>

<style lang="scss" scoped>
    .css-control-indicator {
        width: 18px;
        height: 18px;
        border-radius: 2px;
        border: 1px solid #e9e9e9;
        background-color: #ffffff;
    }

    .css-control-input:checked ~ .css-control-indicator:after {
        top: -1px;
        left: 3px;
        width: 17px;
        height: 9px;
        border-left: 2px solid #fcc142;
        border-bottom: 2px solid #fcc142;
        transform: rotate(-45deg);
    }

    .answer-check {
        padding: 30px;
        @media (max-width: 768px) {
            padding: 20px;
        }

        &__block {
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            &:first-child {
                margin-top: 30px;
            }

            &--correct {
                padding: 15px;
                background-color: #EBFFF2;
            }
        }

        &__form {
            display: flex;
            align-items: baseline;
        }

        &__title {
            color: #A4AEBD;
        }

        &__text {
            font-weight: 600;
            margin-top: 10px;
        }
    }
</style>
